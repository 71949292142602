import Footer from '../../shared/footer/Footer.vue'
import Header from '../../shared/header/Header.vue'

export default {
  name: 'CaseStudies',
  components: {
    Header,
    Footer
  }
}
